import $ from "jquery";

const Events = () => {
  /**
   * On add to cart success, dispatch custom event
   * Later we can listen to that event all over the app
   * @param {*} response
   */
  function handleSuccess(response) {
    const customEvent = new CustomEvent("cartUpdated", { detail: response });
    window.dispatchEvent(customEvent);
  }

  // Scroll to the "Available Dates" when clicking on "Add ticket" button in navigation bar
  $("#nx-event-go2dates").on("click", function () {
    let breadcrumbs = $(".event-page > .breadcrumbs");
    if (breadcrumbs) {
      let header_height =
        breadcrumbs[0].offsetHeight + breadcrumbs[0].offsetTop;
      $("html, body").animate(
        {
          scrollTop: $("#dates").offset().top - header_height,
        },
        100
      );
    }
  });

  // Scroll to the "Available Dates" when clicking on "Add ticket" button in navigation bar
  $("#nx-event-go2dates").on("click", function () {
    let breadcrumbs = $(".event-page > .breadcrumbs");
    if (breadcrumbs) {
      let header_height =
        breadcrumbs[0].offsetHeight + breadcrumbs[0].offsetTop;
      $("html, body").animate(
        {
          scrollTop: $("#dates").offset().top - header_height,
        },
        100
      );
    }
  });
  // Show/Hide the after-three-firsts Available Dates
  $("#nx-event-seemoreavailabledates").on("click", function () {
    let seemore = $(".nx-events-detail-eventdates-seemore");
    let wrapper = seemore.find(".nx-events-detail-eventdates-seemore-wrapper");
    if (seemore.hasClass("nx-open")) {
      wrapper.slideUp(400, function () {
        seemore.removeClass("nx-open");
      });
    } else {
      wrapper.slideDown(400, function () {
        seemore.addClass("nx-open");
      });
    }
  });

  $(".nx-event-sticky-btn-close").on("click", function () {
    $(this)
      .closest(".nx-event-sticky")
      .fadeOut(400, function () {
        $("#nx-event-sticky-msg").text("");
        $(this).css("opacity", "0");
      });
  });

  // ===--- BEGIN: carousel
  var nxCarousel = document.getElementById("nxEventCarousel");
  if (nxCarousel) {
    nxCarousel.addEventListener("slide.bs.carousel", function (el) {
      $(nxCarousel).find(`.nx-events-detail-carousel-caption`).hide();
      let caption = $(nxCarousel).find(`.nx-events-detail-carousel-caption[data-nx-slide='${el.relatedTarget.dataset.nxSlide}']`);
      if (caption.length) {
        caption.show();
      }
    });
  }
  // ===--- END: carousel


  //Show/Hide breadcrumbs 

  let prevScrollpos = $(window).scrollTop();

  $(window).scroll(function() {
      let currentScrollPos = $(window).scrollTop();
      if (prevScrollpos > currentScrollPos) {
          $(".page-wrapper-events .breadcrumbs").css("top", "0");
      } else {
          $(".page-wrapper-events .breadcrumbs").css("top", "-60px");
      }
      prevScrollpos = currentScrollPos;
  });
  
  /**
   * Add event to cart
   *
   */
  // Add Event Date to the cart
  $(".nx-add2cart").on("click", function () {
    const button = $(this);
    let lang = $("#language-code").val();
    let product = $(this).data("product");
    let date = $(this).data("date");
    let quantity = $(this).data("quantity");
    var loader = $(this).find("div");
    button.prop("disabled", true);

    loader.toggle();
    $.ajax({
      type: "POST",
      url: `/${lang}/shop-api/add2cart/event/`,
      data: {
        product: product,
        date: date,
        quantity: quantity,
      },
      dataType: "json",
      success: function (r) {
        button.removeAttr("disabled");
        loader.toggle();
        // TODO: ouvrir le panneau du cart
        if (r.valid) {
          $("#nx-cart-count").text(r.counts.tickets);

          // Dispatch a custom event
          handleSuccess(r);

          // show expired tickets message if needed
          if (r.counts.expired_tickets_msg) {
            button.prop("disabled", true);
            button.removeClass("nx-add2cart");
            $(".nx-event-sticky .sticky-message").removeClass("success");
            $(".nx-event-sticky .sticky-message").removeClass("error");
            $(".nx-event-sticky .sticky-message").addClass("warning");
            if (lang == "en") {
              button.find("span").text("Sold out");
              $("#nx-event-sticky-msg").text("Maximum available tickets reached.");
            } else {
              button.find("span").text("Épuisé");
              $("#nx-event-sticky-msg").text("Maximum de billets disponibles atteint.");
            }
            $(".nx-event-sticky").fadeIn(400, function () {
                $(this).css("opacity", "1");
            });
          }
        } else if (r.msg) {
          // show error message
          $(".nx-event-sticky .sticky-message").removeClass("success");
          $(".nx-event-sticky .sticky-message").removeClass("warning");
          $(".nx-event-sticky .sticky-message").addClass("error");
          $("#nx-event-sticky-msg").text(r.msg);
          $(".nx-event-sticky").fadeIn(400, function () {
              $(this).css("opacity", "1");
          });
        }
      },
      error: function (e) {
        // show error message
        $(".nx-event-sticky .sticky-message").removeClass("success");
        $(".nx-event-sticky .sticky-message").removeClass("warning");
        $(".nx-event-sticky .sticky-message").addClass("error");
        $("#nx-event-sticky-msg").text(e.responseText);
        $(".nx-event-sticky").fadeIn(400, function () {
            $(this).css("opacity", "1");
        });
      },
    });
  });
};

export default Events;
