import $ from "jquery";

const MobileViewportHeight = () => {

    function mobilevh () {
        let vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty("--vh", `${vh}px`);
    }

    window.addEventListener("resize", () => {
        mobilevh();
    });

    $(function(){
        mobilevh();
    });

};

export default MobileViewportHeight;
