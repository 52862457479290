const Featured = () => {
    const slider = document.querySelector('.featured-carousel');

    if (slider) {
        let drag = false;

        let mouseDown = false;
        let startX, scrollLeft;

        let startDragging = function (e) {
            mouseDown = true;
            startX = e.pageX - slider.offsetLeft;
            scrollLeft = slider.scrollLeft;
            drag = false;
        };
        let stopDragging = function (e) {
            mouseDown = false;
            if(!drag && e.target.parentElement.dataset.href) {
                window.location = e.target.parentElement.dataset.href;
            }
        };

        slider.addEventListener('mousemove', (e) => {
            e.preventDefault();
            if(!mouseDown) { return; }
            const x = e.pageX - slider.offsetLeft;
            const scroll = x - startX;
            slider.scrollLeft = scrollLeft - scroll;
        });

        slider.addEventListener('mousedown', startDragging, false);
        slider.addEventListener('mouseup', stopDragging, false);
        slider.addEventListener('mouseleave', stopDragging, false);
        slider.addEventListener('mousemove', () => {
            drag = true;
        });
    }
};

export default Featured;