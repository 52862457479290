import $ from "jquery";

(function($) {
    $.fn.hasScrollBar = function() {
        return this.get(0).scrollWidth > this.get(0).clientWidth;
    };
})($);

const UpcomingEvents = () => {
    let tabs = $(".nx-tabs-event-categories");

    if (tabs.length) {
        doScrollCaret();

        window.addEventListener("resize", () => {
            doScrollCaret();
        });
    }

    function doScrollCaret () {
        tabs.each(function () {
            if ($(this).hasScrollBar()) {
                $(this).parent().find(".nx-tabs-carets").css("display", "inline-block");
            } else {
                $(this).parent().find(".nx-tabs-carets").css("display", "none");
            }
        });
    }

    $(".nx-tabs-caret-left").on("mousedown", function () {
        let tabs = $(this).parent().find(".nx-tabs-event-categories");
        var leftPos = tabs.scrollLeft();
        tabs.animate({scrollLeft: leftPos - 100}, 400);
    });

    $(".nx-tabs-caret-right").on("mousedown", function () {
        let tabs = $(this).parent().find(".nx-tabs-event-categories");
        var leftPos = tabs.scrollLeft();
        tabs.animate({scrollLeft: leftPos + 100}, 400);
    });
};

export default UpcomingEvents;
