import $ from "jquery";

const About = () => {

    $(".nx-about-toggle").on("click", function () {
        const parent = $(this).parents(".nx-about-location");
        const hidden = parent.find(".nx-about-location-hiddenpart");
        if (!parent.hasClass("nx-open")) {
            parent.addClass("nx-open");
            hidden.slideDown();
        } else {
            parent.removeClass("nx-open");
            hidden.slideUp();
        }
    });

    // ===--- BEGIN: carousel
    var nxCarousel = $(".nx-carousel-locations");
    if (nxCarousel.length) {
        nxCarousel.each(function () {
            let self = this;
            this.addEventListener("slide.bs.carousel", function (el) {
                $(self).find(`.nx-events-detail-carousel-caption`).hide();
                let caption = $(self).find(`.nx-events-detail-carousel-caption[data-nx-slide='${el.relatedTarget.dataset.nxSlide}']`);
                if (caption.length) {
                caption.show();
                }
            });
        });
    }
    // ===--- END: carousel

};

export default About;