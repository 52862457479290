// This is the scss entry file
import "../../vendors/bootstrap-icons/bootstrap-icons.min.css";
import "../../vendors/mdb/mdb.min.css";
import "../styles/index.scss";
import "../styles/main.css";

import "bootstrap/dist/js/bootstrap.bundle";
import MobileViewportHeight from "../components/MobileViewportHeight";
import SwitchLanguage from "../components/SwitchLanguage";
import TovNavigation from "../components/TovNavigation";
import Featured from "../components/Featured";
import UpcomingEvents from "../components/UpcomingEvents";
import UpcomingEventsScroller from "../components/UpcomingEventsScroller";
import RegisterForm from "../components/RegisterForm";
import Events from "../components/Events";
import EventsList from "../components/EventsList";
import Attractions from "../components/Attractions";
import AttractionsList from "../components/AttractionsList";
import StickyMessage from "../components/StickyMessage";
import AnnouncementBanner from "../components/AnnouncementBanner";
import EventNotificationUser from "../components/EventNotificationUser";
import YoutubeIframeAPI from "../components/YoutubeIframeAPI";
import About from "../components/About";

window.document.addEventListener("DOMContentLoaded", function () {
  MobileViewportHeight();
  SwitchLanguage();
  TovNavigation();
  Featured();
  UpcomingEvents();
  UpcomingEventsScroller();
  RegisterForm();
  Events();
  EventsList();
  Attractions();
  AttractionsList();
  StickyMessage();
  AnnouncementBanner();
  EventNotificationUser();
  YoutubeIframeAPI();
  About();
});
