import { getCookie, setCookie } from "./utils";

const SwitchLanguage = () => {

    if (!getCookie("django_language")) {
        setCookie("django_language", "fr", 30);
    }

    if (window.location.pathname.startsWith("/en/")) {
        setCookie("django_language", "en", 30);
    } else {
        setCookie("django_language", "fr", 30);
    }

};

export default SwitchLanguage;
