import Cookies from 'js-cookie';
import $ from "jquery";
const EventNotificationUser = () => {
    // Toggle notifications call
    $("#nx-event-togglenotifications").on("click", function () {
        var event_id = $('.event-notif-enable').data('event-notif-enable');
        const CSRF_TOKEN = Cookies.get('csrftoken');
        var url_create_event_notif, url_delete_event_notif, success_message_create, success_message_delete, warning_not_logged;
        var language = $("#language-code").val();
   
        if (language === 'fr') {
            url_create_event_notif = "/fr/shop-api/create-event-notif/";
            url_delete_event_notif = "/fr/shop-api/delete-event-notif/";
            success_message_create =  "Abonnement créé avec succès";
            success_message_delete =  "Abonnement supprimé avec succès";
            warning_not_logged = "Vous devez vous connecter pour vous abonner à cet événement.";
        } else {
            url_create_event_notif = "/en/shop-api/create-event-notif/";
            url_delete_event_notif = "/en/shop-api/delete-event-notif/";
            success_message_create =  "Subscription successfully created";
            success_message_delete =  "Subscription successfully deleted";
            warning_not_logged = "You must login to subscribe to this event.";
        }

        if ($("#nx-event-togglenotifications").hasClass("nx-enable-notifications")) {
            $.ajax({
                url: url_create_event_notif,
                type: "POST",
                headers: {
                    'X-CSRFToken': CSRF_TOKEN,
                    "Content-Type": "application/json",
                },
                data: JSON.stringify({
                    "event_id": event_id,
                    "notification_state":"enabled"
                }),
                dataType: "json",
                beforeSend: function(xhr) {
                    xhr.setRequestHeader("X-CSRFToken", '{{ csrf_token }}');
                },
                success: function (data) {
                    if (data.success) {
                        $("#nx-event-sticky-msg").text(success_message_create);
                        $("#nx-event-togglenotifications").addClass("nx-disable-notifications");
                        $("#nx-event-togglenotifications").removeClass("nx-enable-notifications");
                    }
                },
                error: function (xhr) {
                    if(xhr.status == 401){
                        $("#nx-event-sticky-msg").text(warning_not_logged);
                        $(".nx-event-sticky").find(".sticky-message").removeClass("success").addClass("error");
                        $(".nx-event-sticky").fadeIn(400, function () {
                            $(this).css("opacity", "1");
                        });
                        setTimeout(function () {
                            $(".nx-event-sticky").fadeOut(400, function () {
                                $("#nx-event-sticky-msg").text("");
                                $(this).css("opacity", "0");
                            });
                        }, 2000);
                    }
                }
            });
        } else {
            $.ajax({
                url: url_delete_event_notif,
                type: "POST",
                headers: {
                    'X-CSRFToken': CSRF_TOKEN,
                    "Content-Type": "application/json",
                },
                data: JSON.stringify({
                    "event_id": event_id
                }),
                dataType: "json",
                beforeSend: function(xhr) {
                    xhr.setRequestHeader("X-CSRFToken", '{{ csrf_token }}');
                },
                success: function (data) {
                    if (data.success) {
                        $("#nx-event-sticky-msg").text(success_message_delete);
                        $("#nx-event-togglenotifications").addClass("nx-enable-notifications");
                        $("#nx-event-togglenotifications").removeClass("nx-disable-notifications");
                    }
                },
                error: function (error,xhr) {
                    if(xhr.status == 401){
                        $("#nx-event-sticky-msg").text(warning_not_logged);
                        $(".nx-event-sticky").find(".sticky-message").removeClass("success").addClass("error");
                        $(".nx-event-sticky").fadeIn(400, function () {
                            $(this).css("opacity", "1");
                        });
                        setTimeout(function () {
                            $(".nx-event-sticky").fadeOut(400, function () {
                                $("#nx-event-sticky-msg").text("");
                                $(this).css("opacity", "0");
                            });
                        }, 2000);
                    }
                }
            });
        }
    });

    $(document).ready(function() {
        var event_id = $('.event-notif-enable').data('event-notif-enable');
        var url_create_event_notif;
        var language = $("#language-code").val();
    
        if (language === 'fr') {
            url_create_event_notif = "/fr/shop-api/create-event-notif/";
            
        } else {
            url_create_event_notif = "/en/shop-api/create-event-notif/";
            
        }

        if (event_id) {
            $.ajax({
                url: url_create_event_notif,
                data: {'event_id': event_id},
                success: function(response) {
                    var notification_state = response.notification_state;
                    if (notification_state === 'enabled') {
                        $("#nx-event-togglenotifications").addClass("nx-disable-notifications");
                        $("#nx-event-togglenotifications").removeClass("nx-enable-notifications");
                    } else{
                        $("#nx-event-togglenotifications").addClass("nx-enable-notifications");
                        $("#nx-event-togglenotifications").removeClass("nx-disable-notifications");
                    }
                },
                error: function (xhr) {
                    if(xhr.status == 401){
                        $("#nx-event-togglenotifications").addClass("nx-enable-notifications");
                        $("#nx-event-togglenotifications").removeClass("nx-disable-notifications");
                    }
                }
            });
        }
    });
};
export default EventNotificationUser;