import $ from "jquery";

const TovNavigation = () =>  {
    $('.burger-bars').on('click', function () {
        const nav = $('.nav-panel-mob');

        if(nav.hasClass('show')) {
            nav.removeClass('show');
            nav.removeClass('animate__slideInRight');
            nav.addClass('animate__slideOutRight');
            setTimeout(function () {
                nav.hide();
            }, 500);

            const imgSrc = $('img', this).attr('src');
            $('img', this).attr('src', imgSrc.replace('menu-close.svg', 'menu.svg'));
        } else {
            nav.addClass('show');
            nav.removeClass('animate__slideOutRight');
            nav.show();
            nav.addClass('animate__slideInRight');

            const imgSrc = $('img', this).attr('src');
            $('img', this).attr('src', imgSrc.replace('menu.svg', 'menu-close.svg'));
        }
    });

    $('.open-login').on('click', function (e) {
        e.preventDefault();
        $('.user-overlay').addClass('show');
    });

    $('.user-overlay-close').on('click', function (e) {
        e.preventDefault();
        $('.user-overlay').removeClass('show');
    });

    $(document).mouseup(function(e) {
        const container = $('.card-login');
        if (!container.is(e.target) && container.has(e.target).length === 0) {
            $('.user-overlay').removeClass('show');
        }
    });
};

export default TovNavigation;