import $ from "jquery";
import { getCookie } from "./utils";

const AttractionsList = () => {

    const MODELS = $("#nx-attractions-list-models");

    if ($("#nx-attractions-list-items").length && MODELS.length) {
        nxGetAttractionsList(true);

        let next = $("#nx-attractions-list-next");

        if (next.length) {
            setInterval(function () {
                let wS = $(this).scrollTop();
                let wH = $(window).height();
                let hT = next.offset().top;
                let hH = next.outerHeight();
                if (!$("#nx-attractions-list-loading").is(":visible") && next.data("next") == "true" && wS > (hT + hH - wH) && (hT > wS) && (wS+wH > hT+hH)) {
                    nxGetAttractionsList();
                }
            }, 1000);
        }
    }

    $(".nx-tabs-event-category").on("click", function (event) {
        event.preventDefault();
        let next = $("#nx-attractions-list-next");
        if (next.length) {
            next.data("url", null);
        }
        let tabs = $(this).closest(".nx-tabs-event-categories");
        let tab = $(this).closest("li");
        let value = $(this).data("value");
        tabs.data("category", value);
        tabs.children("li").removeClass("active");
        tab.addClass("active");
        nxGetAttractionsList(true);
    });

    $(".nx-tabs-event-location").on("click", function (event) {
        event.preventDefault();
        let next = $("#nx-attractions-list-next");
        if (next.length) {
            next.data("url", null);
        }
        let tabs = $(this).closest(".nx-tabs-event-locations");
        let tab = $(this).closest("li");
        let value = $(this).data("value");
        tabs.data("location", value);
        tabs.children("li").removeClass("active");
        tab.addClass("active");
        nxGetAttractionsList(true);
    });

    function nxGetAttractionsList (fresh=false) {
        const LANG = getCookie("django_language", "fr");
        let category = $(".nx-tabs-event-categories");
        let location = $(".nx-tabs-event-locations");
        let attractions_list = $("#nx-attractions-list-items");
        let loader = $("#nx-attractions-list-loading");
        let next = $("#nx-attractions-list-next");
        // ===---
        let url = `/${LANG}/attractions-api/attractions/list/?category=${category.data("category")}&location=${location.data("location")}`;
        if (next.length && next.data("url")) {
            url = next.data("url");
        }
        // ===---
        if (fresh) {
            attractions_list.html("");
        }
        // ===---
        loader.show();
        category.css("pointer-events", "none");
        location.css("pointer-events", "none");
        if (next.length) {
            next.data("url", null);
        }
        $.get(url).done((r) => {
            setTimeout(function () {
                if (r.results.length) {
                    nxSetAttractionsList(r.results);
                } else {
                    let cloned_line = $("#nx-attractions-list-models").find(".nx-event-item-none").clone();
                    cloned_line.appendTo(attractions_list);
                    next.data("next", "false");
                }
                if (next.length) {
                    if (r.next) {
                        next.data("next", "true");
                        next.data("url", r.next);
                    } else {
                        next.data("next", "false");
                    }
                }
                loader.hide();
                category.css("pointer-events", "auto");
                location.css("pointer-events", "auto");
            }, 500);
        }).fail(() => {
            let cloned_line = $("#nx-attractions-list-models").find(".nx-event-item-none").clone();
            cloned_line.appendTo(attractions_list);
            next.data("next", "false");
            loader.hide();
            category.css("pointer-events", "auto");
            location.css("pointer-events", "auto");
        });
    }

    function nxSetAttractionsList (events) {
        const LANG = getCookie("django_language", "fr");
        let bgcolour = "yellow";
        events.forEach(function (el) {
            let attractions_list = $("#nx-attractions-list-items");
            let models = $("#nx-attractions-list-models");
            let cloned_line;
            // ===---
            let title = el["title"];
            let description = el["content_short"];
            let categories = el["categories"];
            let img_url = el["thumbnail"];
            let img_title = el["image_main_title"];
            let video_url = el["video_main_file"] ? el["video_main_file"]["file"] : null;
            let url_slug = "#";
            if (LANG == "en") {
                url_slug = `/en/attractions/${el["id"]}-${el["slug_en"]}/`;
            } else {
                url_slug = `/fr/attraits/${el["id"]}-${el["slug_fr"]}/`;
            }
            // ===---
            if (img_url || video_url) {
                cloned_line = models.find(".nx-attraction-item-withimg").clone();
            } else if (bgcolour == "yellow") {
                bgcolour = "black";
                cloned_line = models.find(".nx-attraction-item-bgyellow").clone();
            } else if (bgcolour == "black") {
                bgcolour = "yellow";
                cloned_line = models.find(".nx-attraction-item-bgblack").clone();
            }
            // ===---
            if (cloned_line) {
                cloned_line.attr("onclick", `document.location='${url_slug}'`);
                // ===---
                if (img_url) {
                    cloned_line.find(".thumb").css("background-image", `url('${img_url}')`);
                    cloned_line.find(".thumb").attr("alt", img_title);
                } else if (categories[0]) {
                    cloned_line.find(".thumb").css("background-color", categories[0]["colour"]);
                    cloned_line.find(".thumb").attr("alt", "");
                } else {
                    cloned_line.find(".thumb").css("background-color", "#191b23");
                    cloned_line.find(".thumb").attr("alt", "");
                }
                if (categories[0]) {
                    cloned_line.find(".line-side span").css("background-color", categories[0]["colour"]);
                    categories.forEach(function (c) {
                        cloned_line.find(".nx-attraction-item-categories").append(`<span>${c.name}</span>`);
                    });
                }
                cloned_line.find(".title-wrapper h3, .bs-wrapper h3").text(title);
                cloned_line.find(".nx-attraction-item-link").attr("href", url_slug);
                cloned_line.find(".nx-attraction-item-description").html(description);
                // ===---
                cloned_line.appendTo(attractions_list);
            }
        });
    }

};

export default AttractionsList;
