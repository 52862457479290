import $ from "jquery";

const YoutubeIframeAPI = () => {

    function onYoutubeIframeAPIReady () {
        new window.YT.Player('nx-header-video-frame', {
          playerVars: {
            "autoplay": 1,
            "controls": 0,
            "loop": 1,
            "mute": 1,
            "playsinline": 1
        },
          events: {
            "onReady": onPlayerReady
          }
        });
    }

    function onPlayerReady(event) {
      event.target.setVolume(0);
      event.target.playVideo();
    }

    $(function(){
      if ($("#nx-header-video-frame").length) {
        onYoutubeIframeAPIReady();
      }
    });

};

export default YoutubeIframeAPI;
