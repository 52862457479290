import $ from "jquery";

const Attractions = () => {

    // ===--- BEGIN: carousel
    var nxCarousel = document.getElementById("nxAttractionCarousel");
    if (nxCarousel) {
      nxCarousel.addEventListener("slide.bs.carousel", function (el) {
        $(nxCarousel).find(`.nx-attractions-detail-carousel-caption`).hide();
        let caption = $(nxCarousel).find(`.nx-attractions-detail-carousel-caption[data-nx-slide='${el.relatedTarget.dataset.nxSlide}']`);
        if (caption.length) {
          caption.show();
        }
      });
    }
    // ===--- END: carousel

};

export default Attractions;