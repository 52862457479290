import $ from "jquery";

const StickyMessage = () => {

    $(".nx-dialog-sticky-btn-close").on("click", function () {
        $(this)
        .closest(".nx-dialog-sticky")
        .fadeOut(400, function () {
            $("#nx-dialog-sticky-msg").text("");
            $(this).css("opacity", "0");
        });
    });
};

export default StickyMessage;