import $ from "jquery";

const RegisterForm = () => {

    $("#nx-form-register,#nx-form-profile").on("keyup", function() {
        const el_first_name = $(this).find("input[name='first_name']");
        const first_name = el_first_name.val();
        const el_last_name = $(this).find("input[name='last_name']");
        const last_name = el_last_name.val();
        const el_email = $(this).find("input[name='email']");
        const email = el_email.val();
        const el_password = $(this).find("input[name='password']");
        const password = el_password.val();
        const el_password2 = $(this).find("input[name='password2']");
        const password2 = el_password2.val();
        // const el_address = $(this).find("input[name='address']");
        // const address = el_address.val();
        const el_address_postalcode = $(this).find("input[name='address_postalcode']");
        const address_postalcode = el_address_postalcode.val();

        if (first_name && first_name.length > 0 && first_name.length < 120) {
            el_first_name.css("border-bottom", "1px solid #77dcc3");
        } else if (first_name && (first_name.length <= 0 || first_name.length >= 120)) {
            el_first_name.css("border-bottom", "1px solid #ff0738");
        } else {
            el_first_name.css("border-bottom", "1px solid #ced4da");
        }
        
        if (last_name && last_name.length > 0 && last_name.length < 120) {
            el_last_name.css("border-bottom", "1px solid #77dcc3");
        } else if (last_name && (last_name.length <= 0 || last_name.length >= 120)) {
            el_last_name.css("border-bottom", "1px solid #ff0738");
        } else {
            el_last_name.css("border-bottom", "1px solid #ced4da");
        }

        // eslint-disable-next-line
        let email_regex = new RegExp("[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?");
        
        if (email && email.length > 0 && email_regex.test(email)) {
            el_email.css("border-bottom", "1px solid #77dcc3");
        } else if (email && (email.length <= 0 || !email_regex.test(email))) {
            el_email.css("border-bottom", "1px solid #ff0738");
        } else {
            el_email.css("border-bottom", "1px solid #ced4da");
        }

        var password_regex = new RegExp("^(?=.{12,})(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[$&+,:;=?@#|'<>.^*()%!-])");
        
        if (password && password.length > 0 && password_regex.test(password)) {
            el_password.css("border-bottom", "1px solid #77dcc3");
        } else if (password && (password.length <= 0 || !password_regex.test(password))) {
            el_password.css("border-bottom", "1px solid #ff0738");
        } else {
            el_password.css("border-bottom", "1px solid #ced4da");
        }
        
        if (password2 && password2.length > 0 && password2 == password) {
            el_password2.css("border-bottom", "1px solid #77dcc3");
        } else if (password2 && (password2.length <= 0 || !password_regex.test(password2))) {
            el_password2.css("border-bottom", "1px solid #ff0738");
        } else {
            el_password2.css("border-bottom", "1px solid #ced4da");
        }
        
        /*
        if (address && address.length > 0) {
            el_address.css("border-bottom", "1px solid #77dcc3");
        } else if (address && (address.length <= 0)) {
            el_address.css("border-bottom", "1px solid #ff0738");
        } else {
            el_address.css("border-bottom", "1px solid #ced4da");
        }
        */
        
        if (address_postalcode && address_postalcode.length > 0 && address_postalcode.length <= 6) {
            el_address_postalcode.css("border-bottom", "1px solid #77dcc3");
        } else if (address_postalcode && (address_postalcode.length <= 0 || address_postalcode.length > 6)) {
            el_address_postalcode.css("border-bottom", "1px solid #ff0738");
        } else {
            el_address_postalcode.css("border-bottom", "1px solid #ced4da");
        }
    });

};
    
export default RegisterForm;
