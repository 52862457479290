import $ from "jquery";

const AnnouncementBanner = () => {
  function handleBannerClose() {
    document.querySelector('.sticky-message-wrapper').style.display = 'none';
    var update_last_date_sticky = document.querySelector('.sticky-message-wrapper').getAttribute('data-last-update');
    const expirationDate = new Date(Date.now() + 30 * 24 * 60 * 60 * 1000); // expiration 30jours
    document.cookie = "bannerLastUpdate="+ update_last_date_sticky +";expires=" + expirationDate.toISOString() + ";path=/;";
  }
  function getCookie(name) {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
  }

  function showBanner() {
    const bannerLastUpdate = getCookie('bannerLastUpdate');
    const language = document.getElementById("language-code").value;
    let url;
    if (language === 'fr') {
      url = "/fr/banniere-annonce/";
    } else {
      url = "/en/annonce-banner/";
    }
    fetch(url)
      .then(response => response.json())
      .then(data => {
        if (data.valid) {
          const updatedDate = new Date(data.updated_at);
          const updatedDateString = updatedDate.toISOString(); // Convert to string
          if (bannerLastUpdate !== updatedDateString) { // Compare as strings
            const bannerData = JSON.parse(data.banner);
            const bannerMessage = bannerData.fields.banner_message;
            const msgElement = document.querySelector('.msg');
            
            const currentDate = new Date();
            const daysSinceUpdate = Math.floor((currentDate - updatedDate) / (24 * 60 * 60 * 1000));
            if (daysSinceUpdate >= 0) {
              msgElement.innerHTML = `<p>${bannerMessage}</p>`;
              const sticky_message = document.querySelector('.sticky-message-wrapper');
              sticky_message.setAttribute('data-last-update', updatedDateString); // Update attribute with converted string
              sticky_message.style.display = 'block';
              const expirationDate = new Date(Date.now() + 30 * 24 * 60 * 60 * 1000);
              document.cookie = "bannerLastUpdate=" + updatedDateString + ";expires=" + expirationDate.toUTCString() + ";path=/;";
            }
          }
        }
      })
      .catch(error => console.error(error));
  }
  

  const closeButton = document.getElementById('closeButton'); 
  closeButton.addEventListener('click', handleBannerClose);
  if (!$(".nx-page-profile").length && !$(".nx-page-myreservations").length) {
    showBanner();
  }
};

export default AnnouncementBanner;
